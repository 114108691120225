import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/container"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.metaTitle}
        description={post.frontmatter.metaDescription || post.excerpt}
      />
      <Container>
        <div className="flex flex-wrap justify-between mb-16">
          <img
            className="service-logo"
            src={`/${post.frontmatter.cover}`}
            alt={post.frontmatter.title}
            loading="eager"
            width="480"
            height="480"
          />

          <div className="flex mt-16 md:mt-0 space-x-4 border-t-2 border-t-gray-200 border-b-2 border-b-gray-200 md:border-0 w-full md:w-auto">
            <div className="flex-1 md:flex-auto text-center py-4">
              <p className="mb-2">Our Score</p>
              <p className="text-2xl font-bold mb-0">
                {post.frontmatter.rating}
              </p>
            </div>
            <div className="flex-1 md:flex-auto text-center border-l-2 border-l-gray-200 pl-4 py-4">
              <p className="mb-2">Reviewer Rating</p>
              <div className="text-2xl font-bold flex mb-0 justify-center">
                {Array.apply(null, {
                  length: post.frontmatter.reviewerRating,
                }).map((e, i) => (
                  <img
                    src="/star-rating.svg"
                    className="star bg-no-repeat"
                    key={i}
                    alt="Rating"
                    loading="lazy"
                    width="24"
                    height="24"
                  />
                ))}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="details grid grid-cols-1 md:grid-cols-4">
          <div className="md:border-2 md:border-gray-200 p-8 md:border-r-0 text-center">
            <p>
              <img
                src="/price-icon.svg"
                alt="Price"
                loading="lazy"
                width="100"
                height="100"
              />
            </p>
            <p
              className="mb-0"
              dangerouslySetInnerHTML={{
                __html: post.frontmatter.ratePerMinute,
              }}
            />
          </div>
          <div className="md:border-2 md:border-gray-200 p-8 md:border-r-0 text-center">
            <p>
              <img
                src="/reading-types-icon.svg"
                alt="Reading Type"
                loading="lazy"
                width="100"
                height="100"
              />
            </p>
            <p className="mb-0">{post.frontmatter.channels}</p>
          </div>
          <div className="md:border-2 md:border-gray-200 p-8 md:border-r-0 text-center">
            <p>
              <img
                src="/favorite-reading-type-icon.svg"
                alt="Favorite Reading"
                loading="lazy"
                width="100"
                height="100"
              />
            </p>
            <p className="mb-0">{post.frontmatter.specialties}</p>
          </div>
          <div className="md:border-2 md:border-gray-200 p-8  text-center">
            <p>
              <img
                src="/satisfaction-guarantee-icon.svg"
                alt="Satisfaction Guaranteed"
                loading="lazy"
                width="100"
                height="100"
              />
            </p>
            <p className="mb-0">{post.frontmatter.satisfaction}</p>
          </div>
        </div>
      </Container>
      <div className="section-header global-nav">
        <div className="global-header">
          <h2 className="my-0 text-white text-2xl">
            {" "}
            Top Rated Psychic Service Features
          </h2>
        </div>
      </div>
      <Container>
        <div className="flex flex-wrap md:space-x-8">
          {post.frontmatter.featuresDetail.map((feature, index) => {
            return (
              <div key={index} className="flex md:flex-1 mb-8 w-full md:w-auto">
                <img
                  className="check"
                  src="/check-mark.svg"
                  alt="Feature"
                  loading="lazy"
                  width="100"
                  height="100"
                />
                <p>{feature}</p>
              </div>
            )
          })}
        </div>
      </Container>
      <div className="section-header global-nav">
        <div className="global-header">
          <h2 className="my-0 text-white text-2xl">Full Overview</h2>
        </div>
      </div>
      <Container>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          {/* <header>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
          </header> */}
          <p className="mb-0 font-normal">
            Over {post.frontmatter.yearsOfService} Years in Business
          </p>
          <h1 className="text-lg md:text-2xl text-sans uppercase font-bold mt-1">
            <Link to={post.fields.slug} itemProp="url">
              <span itemProp="headline">{`${post.frontmatter.url}`}</span>
            </Link>
          </h1>

          <a href={post.frontmatter.enrollUrl} target="_blank" rel="noreferrer">
            <h4 className="text-lg font-bold">Special New Customer Offer</h4>
          </a>

          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
        {/* <nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav> */}
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        metaTitle
        metaDescription
        description
        yearsOfService
        featuresFront
        featuresDetail
        url
        cover
        rating
        reviewerRating
        ratePerMinute
        channels
        specialties
        satisfaction
        experts {
          name
          channels
          tagline
          url
          picture
        }
        appStoreUrl
        playStoreUrl
        enrollUrl
      }
      fields {
        slug
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
